import { useContext, useEffect } from "react";
import DailyCoVideoCall from "../../Components/DailyCo/DailyCoVideoCall";
import { Forms } from "../../Components/NegotiationFormComponents/Forms";
import MediaModal from "../../Containers/MediaModal/MediaModal";
import Menu from "../../Containers/Menu/Menu";
import Sidebar from "../../Containers/Sidebar/Sidebar";
import ThreeDScene from "../../Containers/ThreeDScene";
import { UserContext } from "../../Context/Auth/UserContextProvider";
import { UIContext } from "../../Context/UIContextProvider";
import { formsData } from "../../Constants/NegotiationFormData/FormsData";
import axios from "axios";
import { Endpoints } from "../../Constants/EndPoints";
import LobbyAudioCall from "../../Components/DailyCo/LobbyAudioCall";


export default function Home() {
  const { videocall, currentScene, toggleSideMenu, isMenuOpen } = useContext(UIContext)
  const { user } = useContext(UserContext)

  return (
    <>
      <MediaModal />
      <div className="wrapper">

        {
          currentScene.id !== 'avatar' &&
          <Sidebar>
            <header className={`headerBox`}>
              <div className={`sidebar_ctrl_btn ${isMenuOpen ? '' : 'sidebar_ctrl_btn_closed'}`} onClick={toggleSideMenu}>
                {/* <i className="icon-angle-right" /> */}
                {
                  isMenuOpen ?
                    <CloseIcon />
                    :
                    <ChatIcon />
                }
              </div>
              <Menu platformMenu={!videocall ? true : videocall.sideLayout} />
            </header>
          </Sidebar>
        }
        {
          videocall
          &&
          <>
            {
              videocall["roomId"] === "TrainingRoom" ?
                <iframe style={{
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: "55",
                }} src={Endpoints.Exihibition} title="Iframe" allow="camera *;microphone *" />
                :
                <DailyCoVideoCall callData={videocall} />
            }
          </>
        }
        {
          // for spatial calls, lobby calls
          user && !videocall &&
          <UIContext.Consumer>
            {
              v =>
                <LobbyAudioCall
                  name={user.displayName ? user.displayName : user.email.split("@")[0]}
                  room={
                    {
                      "docName": "BreakoutA",
                      "roomName": "dj_meta_platform",
                      "callStarted": true,
                      "publicRoomName": "Lobby Room"
                    }
                  }
                  UIContext={v}
                  isCallVisible={true}
                  temporaryRoom={true}
                />
            }
          </UIContext.Consumer>
        }
        {/* <Forms formsData={formsData} formid={1} /> */}
        <ThreeDScene />
        {
          <div id={"debugger"} style={{ zIndex: "999", position: "absolute", top: "5px", left: "5px", backgroundColor: "gray", maxWidth: "30%", display: "none" }}>

          </div>
        }
      </div>
    </>
  );
}


const ChatIcon = () => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.375 19.61L5.4795 17.125H19.875C20.3391 17.125 20.7842 16.9406 21.1124 16.6124C21.4406 16.2842 21.625 15.8391 21.625 15.375V4C21.625 3.53587 21.4406 3.09075 21.1124 2.76256C20.7842 2.43437 20.3391 2.25 19.875 2.25H4.125C3.66087 2.25 3.21575 2.43437 2.88756 2.76256C2.55937 3.09075 2.375 3.53587 2.375 4V19.61ZM6.09375 18.875L2.046 22.1125C1.91734 22.2152 1.7623 22.2796 1.5987 22.2982C1.43511 22.3167 1.26959 22.2888 1.12117 22.2175C0.972752 22.1462 0.847459 22.0345 0.759689 21.8952C0.671919 21.7559 0.625234 21.5946 0.625 21.43V4C0.625 3.07174 0.993749 2.1815 1.65013 1.52513C2.3065 0.868749 3.19674 0.5 4.125 0.5H19.875C20.8033 0.5 21.6935 0.868749 22.3499 1.52513C23.0063 2.1815 23.375 3.07174 23.375 4V15.375C23.375 16.3033 23.0063 17.1935 22.3499 17.8499C21.6935 18.5062 20.8033 18.875 19.875 18.875H6.09375Z" fill="white" />
    <path d="M7.625 11H16.375C16.9583 11 17.25 11.2917 17.25 11.875C17.25 12.4583 16.9583 12.75 16.375 12.75H7.625C7.04167 12.75 6.75 12.4583 6.75 11.875C6.75 11.2917 7.04167 11 7.625 11ZM7.625 5.75H16.375C16.9583 5.75 17.25 6.04167 17.25 6.625C17.25 7.20833 16.9583 7.5 16.375 7.5H7.625C7.04167 7.5 6.75 7.20833 6.75 6.625C6.75 6.04167 7.04167 5.75 7.625 5.75Z" fill="white" />
  </svg>
)
const CloseIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7502 12.1641L1.70718 21.2071L0.292969 19.7928L9.33594 10.7499L0.293121 1.70706L1.70733 0.292847L10.7502 9.33566L19.793 0.292847L21.2072 1.70706L12.1644 10.7499L21.2073 19.7928L19.7931 21.2071L10.7502 12.1641Z" fill="white" />
  </svg>
)
