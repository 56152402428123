import React, { createContext, useState } from "react";
import Enrollment from "../Enrollment";
import HcpOffice from "../HcpOffice";
import Office from "../Office";
import PatientsHome from "../PatientsHome";
import ViewEmail from "../ViewEmail";

export const SlideContext = createContext(null);

let SlideIndexForHotspot = [1, 2];

function RenderSlides({ data, handleClose }) {
  let initalSlide = data.initalSlide != undefined || data.initalSlide != null ? SlideIndexForHotspot[data.initalSlide] : 0
  const [slide, setSlide] = useState(initalSlide);
  return (
    <SlideContext.Provider value={{ setSlide, close: handleClose }}>
      <SwitchComponents slide={slide} />
      {/* <Enrollment /> */}
    </SlideContext.Provider>
  );
}


function SwitchComponents({ slide }) {
  switch (slide) {
    case 0:
      return <Enrollment />;
    case 1:
      return <ViewEmail />;
    case 2:
      return <Office />;
    case 3:
      return <HcpOffice />;
    case 4:
      return <PatientsHome />;
    default:
      return null;
  }
}
export default RenderSlides;
// export default SwitchComponents
