import React, { useState } from "react";
import PersPective from "../Perspective.js";
import {
  perspectivePurple,
  perspectiveBrown,
  perspectiveBlack,
} from "../../Constants/PersPectiveData";

import Style from "./Style.module.css";
import Black from "../SpexFrames/Black";
import Brown from "../SpexFrames/Brown";
import Purple from "../SpexFrames/Purple";
//Perspective Data

const SPECINDEX_MAPPING = ["Black", "Brown", "Purple"];

const Specs_BoothActivity = ({ data, handleClose }) => {
  const flag = SPECINDEX_MAPPING[data?.flagIndex]
    ? SPECINDEX_MAPPING[data.flagIndex]
    : "Purple";
  const [frame, setFrame] = useState(1);
  const frameData =
    flag === "Black"
      ? perspectiveBlack
      : flag === "Brown"
      ? perspectiveBrown
      : perspectivePurple;
  const spexFrame =
    flag === "Black" ? Black : flag === "Brown" ? Brown : Purple;

  function handleFrame(id) {
    setFrame(id);
  }
  return (
    <>
      <div style={{
        position: "relative",
        zIndex: "555",
      }}>
        <nav className={Style.navBar}>
          <h1 className={Style.pageHeading}>
            {flag === "Black"
              ? "Sponsor’s Perspective"
              : flag === "Brown"
              ? "Site’s Perspective"
              : "Patient’s Perspective"}
          </h1>
          <div className={Style.navOptions}>
            <p>
              Move the glasses around to see the full picture. When you have
              finished exploring, click to see another{" "}
              {flag === "Black"
                ? "sponsor stakeholder's"
                : flag === "Brown"
                ? "site stakeholder's"
                : "patient’s"}{" "}
              perspective
            </p>
            {frameData.map((item) => (
              <button
                key={item.key}
                onClick={() => handleFrame(item.key)}
                className={`${Style.CircleBtn} ${
                  frame === item.key && Style.active
                }`}
              >
                {item.key}
              </button>
            ))}
            {/* <button onClick={()=>handleFrame(2)} className={`${Style.CircleBtn} ${frame ===2 && Style.active}`}>2</button>
            <button onClick={()=>handleFrame(3)} className={`${Style.CircleBtn} ${frame ===3 && Style.active}`}>3</button> */}
            <button
              className={Style.OFFBtn}
              onClick={() => {
                if (handleClose) {
                  handleClose();
                }
              }}
            >
              <svg
                width="66"
                height="24"
                viewBox="0 0 66 24"
                fill="none"
                className={Style.SpexIcon}
              >
                <path
                  d="M62.1971 5.01324C58.4054 -1.00775 37.6109 -0.757483 35.589 8.21679H29.7378C27.7159 -0.757483 6.92131 -1.00775 3.12959 5.01324C1.97116 4.63424 0.190666 4.4644 0.0583761 6.9207C-0.0739139 9.35913 1.54933 10.0438 2.79536 10.228C3.97882 17.638 9.19714 25.1428 18.1875 23.0673C26.3162 21.192 29.1442 16.6154 29.7771 12.3732H35.5496C36.1842 16.6154 39.0124 21.192 47.1411 23.0673C56.1315 25.1428 61.348 17.638 62.5314 10.228C63.7792 10.0438 65.4007 9.35913 65.2684 6.9207C65.1361 4.4644 63.3555 4.63424 62.1971 5.01324ZM17.6672 20.1765C10.0981 21.9249 6.16694 14.4273 6.16694 8.38482C6.16694 2.34238 25.6028 1.97953 26.2571 9.91336C26.5539 13.4959 25.2382 18.4299 17.6672 20.1765ZM47.6595 20.1765C40.0886 18.4299 38.7746 13.4959 39.0696 9.91336C39.7239 1.97953 59.1616 2.34238 59.1616 8.38482C59.1616 14.4273 55.2304 21.9249 47.6595 20.1765Z"
                  fill="white"
                />
              </svg>
              Off
            </button>
          </div>
        </nav>

        {frameData.map(
          (item) =>
            frame === item.key && (
              <PersPective
                key={item.key}
                x="700"
                y="160"
                maskID="glass"
                id={item.key}
                setFrame={setFrame}
                frame={frame}
                blurBanner={item.blurImage}
                banner={item.clearImage}
                Spex={spexFrame}
                flag={flag}
              />
            )
        )}
      </div>
    </>
  );
};

export default Specs_BoothActivity;
