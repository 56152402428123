import React from "react";

const Black = ({ style, maskID }) => {
  return (
    <svg
      width="1427"
      height="528"
      viewBox="0 0 1427 528"
      fill="none"
      style={style}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M1410.42 10.2408C1374.87 4.35574 1033.43 -15.7236 858.641 31.0097C815.065 42.665 808.209 88.9487 735.983 93.3242H713.654C713.441 93.3344 713.247 93.3345 713.036 93.3447C712.823 93.3345 712.629 93.3344 712.416 93.3242H690.087C617.861 88.9487 611.001 42.665 567.428 31.0097C392.641 -15.7236 51.2026 4.35574 15.6481 10.2408C-15.8447 15.4465 9.93667 102.412 9.93667 102.412C49.9192 130.975 28.2152 116.693 65.914 306.232C103.613 495.773 169.871 523.035 376.646 528.228C583.421 533.421 608.555 297.144 630.259 220.551C641.603 180.518 679.466 167.46 713.036 164.091C746.604 167.46 784.467 180.518 795.811 220.551C817.515 297.144 842.649 533.421 1049.42 528.228C1256.2 523.035 1322.46 495.773 1360.16 306.232C1397.85 116.693 1376.15 130.975 1416.13 102.412C1416.13 102.412 1441.91 15.4465 1410.42 10.2408V10.2408ZM602.841 195.884C579.993 477.6 445.058 515.531 345.803 510.054C110.468 497.071 99.0432 397.109 76.1951 137.465C67.5548 39.2907 149.365 30.2332 248.699 27.117C621.12 15.4311 606.026 156.617 602.841 195.884ZM1349.87 137.465C1327.03 397.109 1315.6 497.071 1080.27 510.054C981.012 515.531 846.077 477.6 823.229 195.884C820.041 156.617 804.95 15.4311 1177.37 27.117C1276.71 30.2332 1358.52 39.2907 1349.87 137.465"
        fill="black"
      />
      <clipPath id={maskID} style={style}>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M 527.842 169.99 C 504.994 451.705 370.058 489.637 270.803 484.16 C 35.4689 471.177 24.0437 371.215 1.19556 111.571 C -7.44469 13.3965 74.3658 4.33905 173.699 1.22281 C 546.12 -10.4631 531.027 130.723 527.842 169.99 Z M 1274.88 111.571 C 1252.03 371.215 1240.6 471.177 1005.27 484.16 C 906.013 489.637 771.077 451.705 748.229 169.99 C 745.042 130.723 729.95 -10.4631 1102.37 1.22281 C 1201.71 4.33905 1283.52 13.3965 1274.88 111.571 Z"
          fill="#6C3D0F"
          style={{ transform: "translateX(75px)translateY(29px)" }}
        />
      </clipPath>
    </svg>
  );
};

export default Black;
