import React, { createContext, useState, useEffect, useRef } from 'react'
import { AuthUserCookieName } from '../../Constants/EndPoints';
import { auth, login, updateName } from '../../Firebase';
import { loadUser, loadUser_Server } from '../../Firebase/auth';
import { UpdateUserTable, user_ActiveStatus } from '../../Firebase/chatManager';
import { AppString } from '../../Firebase/constant';
import IDM from '../../Manager/IDM';
import { addChatRoomAdmin, getChatRoomAdmin, updateChatRoomAdmin } from '../../Manager/RoomAdminManager';
import { getNotification, updateRoomNotification } from '../../Manager/RoomNotification';
import { setCookie } from '../../Utility';

export const UserContext = createContext();

export default function UserContextProvider(props) {
    const [user, setUser] = useState(null);

    const userCheck = async () => {
        try {
            const return_user = await IDM.checkForUser()
            if (return_user) {
                let __user = {
                    displayName: return_user.unique_name,
                    sub: return_user.sub,
                    email: return_user.email,
                    role: return_user.role,
                    uid: return_user.email,
                }
                localStorage.setItem('userAuth', JSON.stringify(__user))
                //set user
                setUser({ ...__user, isChecked: true })
            } else {
                console.log("Not logged In")
                localStorage.removeItem('userAuth')
                localStorage.clear()
                setUser(null)
            }
        } catch (error) {
            console.log("Not logged In")
            localStorage.removeItem('userAuth')
            setUser(null)
        }
    }

    useEffect(() => {
        // console.log(`-> window.parent.sa_mode = "expo"`)
        window.parent.loginUser = (email, password) => {
            login(email, password)
        }
        // userCheck()

        auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log(user.email, user.displayName)
                let __user = {
                    displayName: user.displayName,
                    sub: user.unique_name,
                    email: user.email,
                    role: "user",
                    uid: user.email,
                }

                localStorage.setItem('userAuth', JSON.stringify(__user))
                //set user                
                let idToken = await user.getIdToken(true)
                localStorage.setItem('userIdToken', idToken)
                window.userToken = idToken
                console.log("idToken")
                console.log(idToken)
                setCookie(AuthUserCookieName, idToken)

                setUser({ ...__user, isChecked: true })

            } else {
                console.log("user not logged in")
                localStorage.removeItem('userAuth')
                localStorage.clear()
                setUser(null)
                // let _user = window.prompt("Please enter your uniqueId", "dummyUser")
                // if(_user){
                //     _user = _user.trim().toLowerCase()
                //     // loadUser_Server(_user + "@djmeta.com", _user)
                //     loadUser(_user + "@djmeta.com",  _user, true)
                // }
            }
        });
    }, [])


    return (
        <UserContext.Provider value={{ user }}>
            {props.children}
        </UserContext.Provider>
    )
}
